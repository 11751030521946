#slider
	margin-top: 45px
	@media( max-width: 500px )
		margin-top: 0px

	.owl-stage
		.owl-item
			height: calc( 100vh - 45px )
			background-position: center
			background-repeat: no-repeat
			background-size: cover
			background-image: url( '../img/slider/slider1.jpg' )
			position: relative
			@media( max-width: 500px )
				height: 100vh

			
			.container
				position: relative
				height: calc( 100vh - 45px )

			.blend
				background-color: rgba( dark-font-color, 0.77 )
				position: absolute
				top: 0
				left: 50px
				width: calc( 100% - 100px )
				height: calc( 100% - 50px )
				@media( max-width: 500px )
					left: 25px
					width: calc(100% - 50px)
					height: calc(100% - 25px)

			.slide-text
				position: absolute
				top: 50%
				transform: translateY( -50% )
				left: 0
				z-index: 1
				color: #fff
				max-width: 50%
				@media( max-width: 1500px )
					left: 5%
				@media( max-width: 400px ),(max-width: 970px)
					left: 20%
				 @media (max-width: 310px)
					left: 25%
				

				h1
					font-weight: 200
					font-size: 80px
					text-transform: uppercase
					@media (max-width: 970px)
						font-size: 50px
					 @media( max-width: 500px )
						font-size: 30px
					@media( max-width: 400px )
						font-size: 22px

					&::after
						display: block
						content:""
						width: 100px
						height: 1px
						background-color: #FFF
						position: absolute
						top: -50px
						left: 0
						@media(max-width:1080px)
							top: -30px
						

					h1 > strong
						font-weight: 600

				p
					font-size: 22px
					font-weight: 100
					color: #fff
					margin-top: 30px

					@media( max-width: 500px )
						font-size: 14px
					// @media( max-width: 310px )
					//     font-size: 16px
					//     text-align: center
	.owl-dots
		position: absolute 
		left: 50%
		transform: translateX( -50% )
		bottom: 40px    
		@media( max-width: 500px )  
			display: none     
	.owl-dot
		background: transparent;
		height: 10px
		width: 10px
		border-radius: 2px
		border: 2px solid blue-font-color
		display: inline-block

	.owl-dot:first-child
		margin-right: 5px
	.owl-dot:last-child
		margin-left: 5px
	.active
		background: blue-font-color
		height: 15px
		width: 15px


