html
	body
		font-family: 'Lato'
		overflow-x: hidden;
		h1,h2,h3,h4
			margin 0
		
		h2
			font-size: 24px
			font-weight: 900
			color: dark-font-color 
		
		p
			color: dark-font-color
			font-size: 16px
			line-height: 30px
		button
			outline: none 
			outline-color: transparent
		.sub-title 
			
			margin: 60px 0px
			.pic-text
				margin-left: 30px
				h1
					font-size: 40px
					
		.section-header
			
			h1
				color: blue-font-color
				font-size: 72px
				font-weight: 800
				text-transform: uppercase
				width: calc( 100% + 22px )
				position: relative
				margin-bottom: 95px
				@media( max-width: 990px )
					font-size: 64px
					width: 100%
				@media( max-width: 500px )
					width: 100%
					font-size: 48px
				@media( max-width: 400px )
					width: 100%
					font-size: 42px
				@media( max-width: 400px )
					font-size: 36px
				@media( max-width: 310px )
					font-size: 26px


				&::after
					display: block
					content:""
					width: calc( 50vw - 50px )
					height: 1px
					background-color: blue-font-color
					position: absolute
					bottom: -35px
					right: 0
					@media( max-width: 400px ),( max-width: 990px )
						width: 100%
		.pic-img
			display: block
			margin-top: 12px
			height: 100%
			max-width: 202px
			max-height: 202px
			float: left
			img 
				max-width: 100%
			svg
				height: 100%;
				width: 100%;
				fill: #95e1de
			@media( max-width:990px)
				display: block
				float: left
				text-align: center

			@media(max-width:400px)
				margin-top: 6px
				img 
					max-width: 100%

		.pic-text
			margin-top: 0;
			padding: 0
			color: dark-font-color
			float: left
			max-width: 100%
			h1
				font-size: 65px
				font-weight: 900
				text-transform: uppercase
			@media( max-width:1325px)
				margin-left: 0
				text-align: left
				float: left
				h1
					font-size: 56px
			@media( max-width:500px)
				h1
					font-size: 20px


		.pic-resize
			@media( max-width:990px)
				margin-left: 0px
			h1
				font-size: 65px		
				@media( max-width:990px)
					font-size: 40px
					line-height: 40px
				@media( max-width:740px)
					font-size: 40px
				@media( max-width:670px)
					font-size: 32px !important
					line-height: 40px
				@media( max-width:560px)
					font-size: 26px	!important
					line-height: 40px
				@media(max-width:470px)
					font-size: 24px !important
					line-height: 36px
				@media(max-width:440px)
					font-size: 16px !important
					line-height: 28px
				@media(max-width:320px)
					font-size: 12px !important
					line-height: 24px


		.text
			color: dark-font-color
			font-size: 16px

		.list
			
			ul
				list-style-type: none
				margin: 0
				padding: 0
				color: dark-font-color
			li

				padding: 0 0px 30px 0px
				list-style-type: none
				display: flex;
				align-items: center
				text-align: left

			
				
				&::before
					margin-right: 30px
					min-height: 15px
					min-width: 15px
					content: ""
					background: transparent url("../img/cropp.png") center no-repeat;
					display: flex;
			.text-h2
				margin: 50px 0

		footer
		
			text-align: center
			display: flex
			flex-direction: column 
			margin-top: 150px

			p

				bottom: 100
				font-size: 24px
				z-index: 1
			img 
				margin-top: 170px

				@media( max-width: 1500px )
					max-width: 100%