header
	display: flex
	flex-direction: row
	justify-content: space-between
	position: fixed
	top: 45px
	left: 0
	width: 100%
	z-index: 9999
	height: 95px
	transition: all  0.15s linear

	&.expanded
		height: 100vh
		background-color: rgba(54,32,96,1)
		transition: all  0.15s linear
	#logo
		padding: 30px 0px 30px 0
		position absolute
		left : 10%
		
		img 
			height: 35px
			
			@media(max-width:505px)
				width: calc(100% - 70px)
			&.dark
				display: none
				
		

	.small-size-button
		display none
	#cross
		fill: #FFF
	nav
		position absolute
		left :45%
		

		ul
			display: flex
			flex-direction: row
			list-style-type: none
			padding:0px 25px 40px 25px

			li
				text-transform: uppercase
				font-size: 14px
				margin-right: 50px

				&:hover
					text-decoration: none
				a
					line-height: 95px
					color: #FFF
					text-decoration: none

					&:hover
						color: blue-font-color 
						text-decoration: none
						padding:40px 0px 40px 0px
						border-bottom: 1px solid blue-font-color 
					&:visited
						text-decoration: none


	#lang-switch
		position: fixed
		right :5%
		top: 5%
		font-size: 14px
		text-transform: uppercase
		
		a
			color: #FFF
			text-decoration: none
			line-height: 95px
			&:first-child
				font-weight: 900
				margin-right: 10px
			&:hover
				color: blue-font-color 
				text-decoration: none

	&.sticky
		background-color: #fff
		top: 0px

			
		.small-size-button
			button	
				svg 
					.st0
						fill: dark-font-color 
				#cross
					fill: dark-font-color 
		#logo
			
			img 

				&.dark
					display: block

				&.light
					display: none
		nav
			ul
				display: flex
				flex-direction: row
				li
					text-transform: uppercase
					font-size: 14px
					font-weight: 700

					&:hover
						text-decoration: none;

					a
						line-height: 95px
						color: dark-font-color 
						text-decoration: none;

						&:hover
							color: blue-font-color 
							text-decoration: none;
		#lang-switch
			position: fixed
			right :5%
			top: 0%
			a
				color: dark-font-color 
				line-height: 95px
			&:hover
				color: dark-font-color 
	&.small-size
		@media(max-width:1180px)
			
			
			.small-size-button
				top: 25px
				position absolute
				display block
				right : 10%
				
				button
					background-color: transparent
					border: none
					outline: none
					#menu-open-button
						display block
						&.invisible
							display: none
					#cross
						display: none
						
						&.visible
							display block
					svg 
						height 40px
						width: 40px
			nav
				height 100%
				display: flex;
				padding-top: 25%
					
				ul
					padding: 0
					margin: 0
					flex-direction: column	
					height : 0 
					visibility hidden
					transition: all  0.10s linear
					text-align: center
					&.visible
						visibility visible
						height : auto
						transition: all  0.10s linear
					li
						margin-bottom: 30px
						a
							margin-right: 0
							text-align: center
							line-height: 30px
							font-size: 30px
							font-weight: 300
							
							&:hover	
								padding: 10px 0 0 0 
								border-bottom: none
							&:focus
								text-decoration: none 
								color: blue-font-color 
								
						&:last-child
							margin-bottom: 0px
			#lang-switch
				position: absolute
				visibility hidden
				height 0
				top: 65%
				right :40%
				transition: all  0.10s linear
				a
					font-size: 30px
				&.visible
					visibility :visible
					height : auto
					transition: all  0.10s linear
		@media(max-width:500px)
			top: 0
			nav
				left :30%