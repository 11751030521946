#product
	font-family: 'Lato'

	.product-set
		margin: 85px auto 0
		width: calc( 100vw - 100px )
		background-image: url( '../img/product-background.png' )
		background-repeat: repeat-x
		background-position: 0 150px
		position: relative

		@media( max-width: 1365px )
			width: 100%

		.bottom-text
			margin: 150px 0px 85px 0
			h2
				&::after
					display: block
					content:""
					width: calc( 10vw - 50px  )
					height: 1px
					background-color: blue-font-color   
					position: absolute
					bottom: 240px
					left: 100

		.flex-wrapper
			display: flex
			flex-direction: row-reverse
			@media( max-width: 400px ),( max-width:990px)
				flex-direction: column-reverse

			.prod-text
				position: relative
				color: dark-font-color


				.section-content
					padding-right: 90px
					@media( max-width: 500px )
						padding-right: 0px

					h2
						font-size: 24px

					p
						font-size: 16px
						margin-bottom: 0
						@media( max-width: 310px )
							text-align: center
							padding: 0px

					button
						margin: 40px 0px 40px 0px
						background: transparent
						color: dark-font-color
						border-radius: 5px
						border: 1px solid dark-font-color
						text-transform: uppercase
						padding: 10px 20px
						font-size : 14px
						font-weight: 800

			.right-img-wrapper
				padding-left: 0
				.right-img
					width: calc( 50vw - 50px )
					height: 100%
					background-image: url('../img/product-pic.jpg')
					background-position: center
					background-size: cover
					@media( max-width: 400px ), ( max-width:990px)
						width: 100%
						height: 400px
						margin-left: 15px

					
	@media( max-width: 1365px )
			width: 100%
	.video
		background-color: #eeedf1
		width: calc(100vw - 44px)
		margin-left: 22px
		margin-right: 22px
		margin-bottom: 50px
		img
			width: 100%
			@media( max-width: 1025px )
				max-width: 100%
