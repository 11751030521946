html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
html body {
  font-family: 'Lato';
  overflow-x: hidden;
}
html body h1,
html body h2,
html body h3,
html body h4 {
  margin: 0;
}
html body h2 {
  font-size: 24px;
  font-weight: 900;
  color: #362060;
}
html body p {
  color: #362060;
  font-size: 16px;
  line-height: 30px;
}
html body button {
  outline: none;
  outline-color: transparent;
}
html body .sub-title {
  margin: 60px 0px;
}
html body .sub-title .pic-text {
  margin-left: 30px;
}
html body .sub-title .pic-text h1 {
  font-size: 40px;
}
html body .section-header h1 {
  color: #95e1de;
  font-size: 72px;
  font-weight: 800;
  text-transform: uppercase;
  width: calc(100% + 22px);
  position: relative;
  margin-bottom: 95px;
}
@media (max-width: 990px) {
  html body .section-header h1 {
    font-size: 64px;
    width: 100%;
  }
}
@media (max-width: 500px) {
  html body .section-header h1 {
    width: 100%;
    font-size: 48px;
  }
}
@media (max-width: 400px) {
  html body .section-header h1 {
    width: 100%;
    font-size: 42px;
  }
}
@media (max-width: 400px) {
  html body .section-header h1 {
    font-size: 36px;
  }
}
@media (max-width: 310px) {
  html body .section-header h1 {
    font-size: 26px;
  }
}
html body .section-header h1::after {
  display: block;
  content: "";
  width: calc(50vw - 50px);
  height: 1px;
  background-color: #95e1de;
  position: absolute;
  bottom: -35px;
  right: 0;
}
@media (max-width: 400px), (max-width: 990px) {
  html body .section-header h1::after {
    width: 100%;
  }
}
html body .pic-img {
  display: block;
  margin-top: 12px;
  height: 100%;
  max-width: 202px;
  max-height: 202px;
  float: left;
}
html body .pic-img img {
  max-width: 100%;
}
html body .pic-img svg {
  height: 100%;
  width: 100%;
  fill: #95e1de;
}
@media (max-width: 990px) {
  html body .pic-img {
    display: block;
    float: left;
    text-align: center;
  }
}
@media (max-width: 400px) {
  html body .pic-img {
    margin-top: 6px;
  }
  html body .pic-img img {
    max-width: 100%;
  }
}
html body .pic-text {
  margin-top: 0;
  padding: 0;
  color: #362060;
  float: left;
  max-width: 100%;
}
html body .pic-text h1 {
  font-size: 65px;
  font-weight: 900;
  text-transform: uppercase;
}
@media (max-width: 1325px) {
  html body .pic-text {
    margin-left: 0;
    text-align: left;
    float: left;
  }
  html body .pic-text h1 {
    font-size: 56px;
  }
}
@media (max-width: 500px) {
  html body .pic-text h1 {
    font-size: 20px;
  }
}
@media (max-width: 990px) {
  html body .pic-resize {
    margin-left: 0px;
  }
}
html body .pic-resize h1 {
  font-size: 65px;
}
@media (max-width: 990px) {
  html body .pic-resize h1 {
    font-size: 40px;
    line-height: 40px;
  }
}
@media (max-width: 740px) {
  html body .pic-resize h1 {
    font-size: 40px;
  }
}
@media (max-width: 670px) {
  html body .pic-resize h1 {
    font-size: 32px !important;
    line-height: 40px;
  }
}
@media (max-width: 560px) {
  html body .pic-resize h1 {
    font-size: 26px !important;
    line-height: 40px;
  }
}
@media (max-width: 470px) {
  html body .pic-resize h1 {
    font-size: 24px !important;
    line-height: 36px;
  }
}
@media (max-width: 440px) {
  html body .pic-resize h1 {
    font-size: 16px !important;
    line-height: 28px;
  }
}
@media (max-width: 320px) {
  html body .pic-resize h1 {
    font-size: 12px !important;
    line-height: 24px;
  }
}
html body .text {
  color: #362060;
  font-size: 16px;
}
html body .list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #362060;
}
html body .list li {
  padding: 0 0px 30px 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  text-align: left;
}
html body .list li::before {
  margin-right: 30px;
  min-height: 15px;
  min-width: 15px;
  content: "";
  background: transparent url("../img/cropp.png") center no-repeat;
  display: flex;
}
html body .list .text-h2 {
  margin: 50px 0;
}
html body footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 150px;
}
html body footer p {
  bottom: 100;
  font-size: 24px;
  z-index: 1;
}
html body footer img {
  margin-top: 170px;
}
@media (max-width: 1500px) {
  html body footer img {
    max-width: 100%;
  }
}
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 45px;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 95px;
  transition: all 0.15s linear;
}
header.expanded {
  height: 100vh;
  background-color: #362060;
  transition: all 0.15s linear;
}
header #logo {
  padding: 30px 0px 30px 0;
  position: absolute;
  left: 10%;
}
header #logo img {
  height: 35px;
}
@media (max-width: 505px) {
  header #logo img {
    width: calc(100% - 70px);
  }
}
header #logo img.dark {
  display: none;
}
header .small-size-button {
  display: none;
}
header #cross {
  fill: #fff;
}
header nav {
  position: absolute;
  left: 45%;
}
header nav ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0px 25px 40px 25px;
}
header nav ul li {
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 50px;
}
header nav ul li:hover {
  text-decoration: none;
}
header nav ul li a {
  line-height: 95px;
  color: #fff;
  text-decoration: none;
}
header nav ul li a:hover {
  color: #95e1de;
  text-decoration: none;
  padding: 40px 0px 40px 0px;
  border-bottom: 1px solid #95e1de;
}
header nav ul li a:visited {
  text-decoration: none;
}
header #lang-switch {
  position: fixed;
  right: 5%;
  top: 5%;
  font-size: 14px;
  text-transform: uppercase;
}
header #lang-switch a {
  color: #fff;
  text-decoration: none;
  line-height: 95px;
}
header #lang-switch a:first-child {
  font-weight: 900;
  margin-right: 10px;
}
header #lang-switch a:hover {
  color: #95e1de;
  text-decoration: none;
}
header.sticky {
  background-color: #fff;
  top: 0px;
}
header.sticky .small-size-button button svg .st0 {
  fill: #362060;
}
header.sticky .small-size-button button #cross {
  fill: #362060;
}
header.sticky #logo img.dark {
  display: block;
}
header.sticky #logo img.light {
  display: none;
}
header.sticky nav ul {
  display: flex;
  flex-direction: row;
}
header.sticky nav ul li {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
header.sticky nav ul li:hover {
  text-decoration: none;
}
header.sticky nav ul li a {
  line-height: 95px;
  color: #362060;
  text-decoration: none;
}
header.sticky nav ul li a:hover {
  color: #95e1de;
  text-decoration: none;
}
header.sticky #lang-switch {
  position: fixed;
  right: 5%;
  top: 0%;
}
header.sticky #lang-switch a {
  color: #362060;
  line-height: 95px;
}
header.sticky #lang-switch:hover {
  color: #362060;
}
@media (max-width: 1180px) {
  header.small-size .small-size-button {
    top: 25px;
    position: absolute;
    display: block;
    right: 10%;
  }
  header.small-size .small-size-button button {
    background-color: transparent;
    border: none;
    outline: none;
  }
  header.small-size .small-size-button button #menu-open-button {
    display: block;
  }
  header.small-size .small-size-button button #menu-open-button.invisible {
    display: none;
  }
  header.small-size .small-size-button button #cross {
    display: none;
  }
  header.small-size .small-size-button button #cross.visible {
    display: block;
  }
  header.small-size .small-size-button button svg {
    height: 40px;
    width: 40px;
  }
  header.small-size nav {
    height: 100%;
    display: flex;
    padding-top: 25%;
  }
  header.small-size nav ul {
    padding: 0;
    margin: 0;
    flex-direction: column;
    height: 0;
    visibility: hidden;
    transition: all 0.1s linear;
    text-align: center;
  }
  header.small-size nav ul.visible {
    visibility: visible;
    height: auto;
    transition: all 0.1s linear;
  }
  header.small-size nav ul li {
    margin-bottom: 30px;
  }
  header.small-size nav ul li a {
    margin-right: 0;
    text-align: center;
    line-height: 30px;
    font-size: 30px;
    font-weight: 300;
  }
  header.small-size nav ul li a:hover {
    padding: 10px 0 0 0;
    border-bottom: none;
  }
  header.small-size nav ul li a:focus {
    text-decoration: none;
    color: #95e1de;
  }
  header.small-size nav ul li:last-child {
    margin-bottom: 0px;
  }
  header.small-size #lang-switch {
    position: absolute;
    visibility: hidden;
    height: 0;
    top: 65%;
    right: 40%;
    transition: all 0.1s linear;
  }
  header.small-size #lang-switch a {
    font-size: 30px;
  }
  header.small-size #lang-switch.visible {
    visibility: visible;
    height: auto;
    transition: all 0.1s linear;
  }
}
@media (max-width: 500px) {
  header.small-size {
    top: 0;
  }
  header.small-size nav {
    left: 30%;
  }
}
#effectivity {
  margin-top: 155px;
}
@media (max-width: 500px) {
  #effectivity {
    margin-top: 100px;
  }
}
#effectivity h1,
#effectivity h2,
#effectivity h3,
#effectivity h4 {
  margin: 0;
}
#effectivity .text-centered {
  font-size: 24px;
  color: #362060;
  font-weight: 300;
  padding: 55px 30px;
  text-align: center;
}
#effectivity .infos-wrapper {
  width: calc(100vw - 62px);
  margin: 0 auto;
  color: #fff;
  text-align: center;
  overflow: hidden;
  padding-top: 205px;
}
@media (max-width: 500px) {
  #effectivity .infos-wrapper {
    padding-top: 55px;
  }
}
#effectivity .infos-wrapper .container {
  position: relative;
  background-color: #362060;
}
#effectivity .infos-wrapper .container .first-row .item {
  position: relative;
}
#effectivity .infos-wrapper .container .first-row .item img {
  position: absolute;
  bottom: calc(100% - 35px);
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 990px) {
  #effectivity .infos-wrapper .container .first-row .item .visi {
    display: block;
    bottom: calc(100% - 35px);
  }
}
@media (max-width: 768px) {
  #effectivity .infos-wrapper .container .first-row .item .visi {
    bottom: calc(100% + 25px);
  }
}
@media (max-width: 500px) {
  #effectivity .infos-wrapper .container .first-row .item .visi {
    display: none;
  }
}
@media (max-width: 400px), (max-width: 990px) {
  #effectivity .infos-wrapper .container .first-row .item .invi {
    display: none;
  }
}
#effectivity .infos-wrapper .container .first-row .item h3 {
  font-size: 49px;
  font-weight: 900;
  color: #fff;
  margin: 60px 0 20px;
}
#effectivity .infos-wrapper .container .first-row .item p {
  color: #fff;
  text-align: center;
  font-weight: 300;
}
#effectivity .infos-wrapper .container .second-row p {
  font-weight: 300;
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 85px;
}
@media (max-width: 800px) {
  #effectivity .infos-wrapper .container .second-row p {
    margin-bottom: 60px;
  }
}
@media (max-width: 500px) {
  #effectivity .infos-wrapper .container .second-row p {
    font-size: 20px;
  }
}
#effectivity .infos-wrapper .container .second-row p::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 102px;
  height: 1px;
  background-color: #95e1de;
  margin-bottom: 55px;
}
#effectivity .infos-wrapper .container .third-row .item {
  padding-bottom: 125px;
}
@media (max-width: 800px) {
  #effectivity .infos-wrapper .container .third-row .item {
    padding-bottom: 60px;
    line-height: 30px;
  }
}
@media (max-width: 500px) {
  #effectivity .infos-wrapper .container .third-row .item {
    padding: 10px;
  }
}
#effectivity .infos-wrapper .container .third-row .item h3 {
  font-size: 100px;
  color: #95e1de;
  font-weight: 900;
}
@media (max-width: 1025px) {
  #effectivity .infos-wrapper .container .third-row .item h3 {
    font-size: 80px;
  }
}
@media (max-width: 800px) {
  #effectivity .infos-wrapper .container .third-row .item h3 {
    font-size: 49px;
    padding-bottom: 0px;
  }
}
#effectivity .infos-wrapper .container .third-row .item p {
  color: #fff;
  text-align: center;
  padding: 0 30px;
}
@media (max-width: 800px) {
  #effectivity .infos-wrapper .container .third-row .item p {
    margin-top: 20px;
  }
}
@media (max-width: 310px) {
  #effectivity .infos-wrapper .container .third-row .item p {
    padding: 0 0px;
  }
}
@media (max-width: 500px) {
  #effectivity .infos-wrapper .container .third-row .item:first-child,
  #effectivity .infos-wrapper .container .third-row .item:last-child {
    padding: 10px;
  }
}
#effectivity .infos-wrapper .container::before {
  content: "";
  display: block;
  position: absolute;
  right: 100%;
  width: 100vw;
  top: 0;
  height: 100%;
  background-color: #362060;
}
#effectivity .infos-wrapper .container .right-picto-wrapper {
  background-color: #95e1de;
  position: absolute;
  left: 100%;
  height: 100%;
  width: 100vw;
  top: 0;
  background-image: url("../img/effectivity/right-picto.png");
  background-repeat: no-repeat;
}
#effectivity .text {
  margin: 100px 0 0;
}
#effectivity .text h2 {
  margin-bottom: 80px;
}
#effectivity .text p {
  margin-bottom: 45px;
}
#effectivity .text span {
  font-weight: bold;
}
#slider {
  margin-top: 45px;
}
@media (max-width: 500px) {
  #slider {
    margin-top: 0px;
  }
}
#slider .owl-stage .owl-item {
  height: calc(100vh - 45px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/slider/slider1.jpg");
  position: relative;
}
@media (max-width: 500px) {
  #slider .owl-stage .owl-item {
    height: 100vh;
  }
}
#slider .owl-stage .owl-item .container {
  position: relative;
  height: calc(100vh - 45px);
}
#slider .owl-stage .owl-item .blend {
  background-color: rgba(54,32,96,0.77);
  position: absolute;
  top: 0;
  left: 50px;
  width: calc(100% - 100px);
  height: calc(100% - 50px);
}
@media (max-width: 500px) {
  #slider .owl-stage .owl-item .blend {
    left: 25px;
    width: calc(100% - 50px);
    height: calc(100% - 25px);
  }
}
#slider .owl-stage .owl-item .slide-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  color: #fff;
  max-width: 50%;
}
@media (max-width: 1500px) {
  #slider .owl-stage .owl-item .slide-text {
    left: 5%;
  }
}
@media (max-width: 400px), (max-width: 970px) {
  #slider .owl-stage .owl-item .slide-text {
    left: 20%;
  }
}
@media (max-width: 310px) {
  #slider .owl-stage .owl-item .slide-text {
    left: 25%;
  }
}
#slider .owl-stage .owl-item .slide-text h1 {
  font-weight: 200;
  font-size: 80px;
  text-transform: uppercase;
}
@media (max-width: 970px) {
  #slider .owl-stage .owl-item .slide-text h1 {
    font-size: 50px;
  }
}
@media (max-width: 500px) {
  #slider .owl-stage .owl-item .slide-text h1 {
    font-size: 30px;
  }
}
@media (max-width: 400px) {
  #slider .owl-stage .owl-item .slide-text h1 {
    font-size: 22px;
  }
}
#slider .owl-stage .owl-item .slide-text h1::after {
  display: block;
  content: "";
  width: 100px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: -50px;
  left: 0;
}
@media (max-width: 1080px) {
  #slider .owl-stage .owl-item .slide-text h1::after {
    top: -30px;
  }
}
#slider .owl-stage .owl-item .slide-text h1 h1 > strong {
  font-weight: 600;
}
#slider .owl-stage .owl-item .slide-text p {
  font-size: 22px;
  font-weight: 100;
  color: #fff;
  margin-top: 30px;
}
@media (max-width: 500px) {
  #slider .owl-stage .owl-item .slide-text p {
    font-size: 14px;
  }
}
#slider .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}
@media (max-width: 500px) {
  #slider .owl-dots {
    display: none;
  }
}
#slider .owl-dot {
  background: transparent;
  height: 10px;
  width: 10px;
  border-radius: 2px;
  border: 2px solid #95e1de;
  display: inline-block;
}
#slider .owl-dot:first-child {
  margin-right: 5px;
}
#slider .owl-dot:last-child {
  margin-left: 5px;
}
#slider .active {
  background: #95e1de;
  height: 15px;
  width: 15px;
}
#benefits {
  font-family: 'Lato';
  margin: 170px 0px 0px 0px;
}
@media (max-width: 500px) {
  #benefits {
    margin-top: 75px;
  }
}
#benefits h1,
#benefits h2,
#benefits h3,
#benefits h4 {
  margin: 0;
}
#benefits .list {
  margin: 50px 0;
}
#benefits .ca {
  display: flex;
  margin: 115px 0px 115px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #362060;
}
@media (max-width: 400px), (max-width: 990px) {
  #benefits .ca {
    flex-direction: row;
  }
}
@media (max-width: 400px) {
  #benefits .ca {
    flex-direction: column;
  }
}
@media (max-width: 990px) {
  #benefits .ca img {
    width: 100%;
  }
}
#benefits .ca p {
  margin-top: 25px;
  width: 90%;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
}
@media (max-width: 990px) {
  #benefits .ca p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 600px) {
  #benefits .ca p {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  #benefits .ca p {
    font-size: 18px;
  }
}
#benefits .ca h1 {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
}
@media (max-width: 990px) {
  #benefits .ca h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  #benefits .ca h1 {
    font-size: 32px;
  }
}
#benefits .ca .text-col {
  display: block;
  width: 33%;
}
@media (max-width: 500px) {
  #benefits .ca .text-col {
    width: 100%;
  }
}
#benefits .ca .arrow-col {
  display: block;
}
@media (max-width: 400px), (max-width: 990px) {
  #benefits .ca .arrow-col {
    margin: 15px 0px 15px 0px;
  }
  #benefits .ca .arrow-col img {
    display: none;
  }
}
#benefits .pic {
  display: inline-block;
  height: 100%;
  margin-top: 75px;
  width: 100%;
}
@media (max-width: 400px) {
  #benefits .pic .pic-img {
    display: block;
    float: left;
  }
}
@media (max-width: 350px) {
  #benefits .pic .pic-img {
    width: 100%;
    text-align: center;
  }
}
#benefits .save {
  width: 100%;
  display: inline-block;
  background-image: url("../img/zzz.png");
  position: relative;
  height: 630px;
  background-size: cover;
  background-position: center;
}
@media (max-width: 400px) {
  #benefits .save {
    width: 100%;
  }
}
#benefits .save::before {
  height: 630px;
  width: 100%;
  display: inline-block;
  content: "";
}
#benefits .save .blend {
  background-color: rgba(54,32,96,0.77);
  position: absolute;
  top: 45px;
  left: 50px;
  bottom: 45px;
  width: calc(100% - 100px);
  height: calc(100% - 90px);
}
@media (max-width: 500px) {
  #benefits .save .blend {
    left: 25px;
    width: calc(100% - 50px);
    height: calc(100% - 40px);
    top: 20px;
  }
}
#benefits .save .container {
  position: relative;
  height: calc(100vh - 90px);
}
#benefits .save .save-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20%;
  right: 20%;
  z-index: 1;
  color: #fff;
  max-width: 100%;
  text-align: center;
}
#benefits .save .save-text h1 {
  font-weight: 500;
  font-size: 37px;
  text-transform: uppercase;
  text-align: center;
  color: #95e1de;
}
@media (max-width: 970px) {
  #benefits .save .save-text h1 {
    font-size: 24px;
  }
}
@media (max-width: 500px) {
  #benefits .save .save-text h1 {
    font-size: 20px;
  }
}
#benefits .save .save-text h2 {
  font-size: 149px;
  font-weight: 900;
  color: #fff;
  margin: 40px 0 55px;
}
@media (max-width: 400px), (max-width: 970px) {
  #benefits .save .save-text h2 {
    font-size: 66px;
    margin: 10px 0 15px;
  }
}
@media (max-width: 300px) {
  #benefits .save .save-text h2 {
    font-size: 66px;
  }
}
#benefits .save .save-text p {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  color: #fff;
}
@media (max-width: 500px) {
  #benefits .save .save-text p {
    font-size: 20px;
  }
}
#analys {
  font-family: 'Lato';
}
#analys h1,
#analys h2,
#analys h3,
#analys h4 {
  margin: 0;
}
#analys .photo {
  margin-top: 65px;
  margin-bottom: 130px;
}
@media (max-width: 500px) {
  #analys .photo {
    margin-bottom: 65px;
  }
}
@media (max-width: 1500px) {
  #analys .photo img {
    max-width: 100%;
  }
}
#analys .second {
  margin-top: 60px;
}
#analys .scope-logo {
  margin: 210px 0px 100px 0px;
  display: inline-block;
}
@media (max-width: 500px) {
  #analys .scope-logo {
    margin: 110px 0px 50px 0px;
  }
}
#analys .scope-logo .scope-text {
  display: block;
  height: 100%;
  float: left;
  width: 52%;
  margin-left: 30px;
}
@media (max-width: 300px), (max-width: 990px) {
  #analys .scope-logo .scope-text {
    margin-left: 0px;
    float: left;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
#analys .scope-logo .scope-text h1 {
  text-transform: uppercase;
  font-size: 65px;
  color: #362060;
  font-weight: 900;
}
@media (max-width: 990px) {
  #analys .scope-logo .scope-text h1 {
    font-size: 44px;
    line-height: 56px;
    float: left;
    margin-left: 20px;
    text-align: left;
  }
}
@media (max-width: 580px) {
  #analys .scope-logo .scope-text h1 {
    font-size: 34px;
  }
}
@media (max-width: 500px) {
  #analys .scope-logo .scope-text h1 {
    font-size: 26px;
    line-height: 34px;
  }
}
@media (max-width: 415px) {
  #analys .scope-logo .scope-text h1 {
    font-size: 16px;
    line-height: 26px;
    margin-left: 10px;
  }
}
#changes {
  font-family: 'Lato';
}
#changes h1,
#changes h2,
#changes h3,
#changes h4 {
  margin: 0;
}
#changes .loc {
  display: flex;
  align-items: center;
}
#changes .text-h2 {
  margin: 85px 0px 45px 0px;
  color: #362060;
  font-weight: 800;
  font-size: 24px;
}
@media (max-width: 360px) {
  #changes .pic-text h1 {
    font-size: 16px;
  }
}
#changes .ul-cropp {
  list-style-image: url("dist\img\cropp.png");
  list-style-type: none;
  color: #362060;
  font-size: 16px;
  margin: 0px 0px 95px 40px;
}
#changes .colo-photo {
  margin-top: 75px;
}
@media (max-width: 500px) {
  #changes .colo-photo {
    margin-top: 25px;
  }
}
#changes .colo-photo img {
  max-width: 100%;
}
#changes .colo-photo .pic2 {
  max-width: 47%;
  float: left;
  display: block;
  margin-right: 45px;
}
#specification {
  font-family: 'Lato';
}
#specification h1,
#specification h2,
#specification h3,
#specification h4 {
  margin: 0;
}
#specification .list .text-h2 {
  margin-bottom: 55px;
}
#specification .spec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
}
@media (max-width: 400px), (max-width: 990px) {
  #specification .spec {
    flex-direction: column;
  }
}
#specification .spec .spec-block {
  height: 100%;
  border: 1px solid #362060;
  width: calc(33.3333333% - 10px);
  border-radius: 2px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
@media (max-width: 990px) {
  #specification .spec .spec-block {
    width: 100%;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 400px) {
  #specification .spec .spec-block {
    max-width: 100%;
    margin-bottom: 15px;
  }
}
#specification .spec .spec-block .first-side-wrapper .spec-block-pic {
  display: inline;
}
#specification .spec .spec-block .first-side-wrapper .spec-block-pic img {
  margin: 90px 0px 80px 0px;
  height: 120px;
}
@media (max-width: 500px) {
  #specification .spec .spec-block .first-side-wrapper .spec-block-pic img {
    margin: 50px 0px 40px 0px;
    width: 35%;
    height: 100px;
  }
}
#specification .spec .spec-block .first-side-wrapper .spec-block-text {
  margin-bottom: 115px;
  color: #362060;
  text-transform: uppercase;
}
#specification .spec .spec-block .first-side-wrapper .spec-block-text p {
  margin: 0;
  font-weight: 900;
  font-size: 28px;
  text-align: center;
}
@media (max-width: 990px) {
  #specification .spec .spec-block .first-side-wrapper .spec-block-text p {
    font-size: 26px;
  }
}
@media (max-width: 500px) {
  #specification .spec .spec-block .first-side-wrapper .spec-block-text p {
    font-size: 22px;
  }
}
#specification .spec .spec-block .second-side-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  text-align: center;
  background-color: #362060;
  opacity: 0;
  margin-top: 100%;
}
#specification .spec .spec-block .second-side-wrapper h1 {
  color: #95e1de;
  text-transform: uppercase;
  font-size: 30px;
  margin: 50px 0px 45px 0px;
  font-weight: 900;
  text-align: center;
}
@media (max-width: 990px) {
  #specification .spec .spec-block .second-side-wrapper h1 {
    margin: 25px 0px 15px 0px;
  }
}
@media (max-width: 500px) {
  #specification .spec .spec-block .second-side-wrapper h1 {
    font-size: 30px;
    margin: 25px 0px 15px 0px;
  }
}
#specification .spec .spec-block .second-side-wrapper .spec-block-middle-text {
  text-align: left;
  line-height: 27px;
  color: #fff;
  margin: 0px 40px 0px 40px;
}
@media (max-width: 400px) {
  #specification .spec .spec-block .second-side-wrapper .spec-block-middle-text {
    margin: 0px 20px 0px 20px;
  }
}
#specification .spec .spec-block .second-side-wrapper .spec-block-middle-text p {
  font-size: 16px;
  margin: 0;
  color: #fff;
  line-height: 25px;
}
@media (max-width: 1200px) {
  #specification .spec .spec-block .second-side-wrapper .spec-block-middle-text p {
    font-size: 13px;
  }
}
@media (max-width: 990px) {
  #specification .spec .spec-block .second-side-wrapper .spec-block-middle-text p {
    font-size: 20px;
    text-align: center;
    margin: 0px 90px 40px 90px;
  }
}
@media (max-width: 600px) {
  #specification .spec .spec-block .second-side-wrapper .spec-block-middle-text p {
    font-size: 18px;
    line-height: 20px;
    margin: 0px 75px 40px 75px;
  }
}
@media (max-width: 500px) {
  #specification .spec .spec-block .second-side-wrapper .spec-block-middle-text p {
    font-size: 14px;
    line-height: 16px;
    margin: 0px 35px 20px 35px;
  }
}
@media (max-width: 370px) {
  #specification .spec .spec-block .second-side-wrapper .spec-block-middle-text p {
    margin: 0px 10px 10px 10px;
    font-size: 14px;
    line-height: 16px;
  }
}
#specification .spec .spec-block:hover .second-side-wrapper {
  opacity: 1;
  margin-top: 0;
  transition: all 0.2s ease-out;
}
#specification .text-spec {
  margin: 95px 0px 85px 0px;
}
#specification .text-spec h2 {
  margin: 80px 0px 65px 0px;
  color: #362060;
  font-size: 24px;
  text-align: center;
}
@media (max-width: 400px) {
  #specification .text-spec h2 {
    font-size: 16px;
  }
}
#specification .pdf-gen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 150px;
}
@media (max-width: 400px), (max-width: 600px) {
  #specification .pdf-gen {
    flex-direction: column;
  }
}
@media (max-width: 400px), (max-width: 600px) {
  #specification .pdf-gen .clouds {
    margin: 0;
    margin-right: auto;
    margin-left: auto;
  }
}
#specification .pdf-gen .clouds img {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}
#specification .pdf-gen .clouds p {
  margin: 0;
  width: 100%;
}
#specification .pdf-gen .clouds:first-child {
  margin-right: 190px;
}
@media (max-width: 400px), (max-width: 600px) {
  #specification .pdf-gen .clouds:first-child {
    margin: 0;
    margin-bottom: 35px;
    margin-right: auto;
    margin-left: auto;
  }
}
#contact {
  font-family: 'Lato';
  margin-top: 170px;
}
#contact h1,
#contact h2,
#contact h3,
#contact h4 {
  margin: 0;
}
#contact .contact-header {
  margin: 80px 0px 115px 0px;
  padding-left: 170px;
  padding-right: 170px;
}
@media (max-width: 610px) {
  #contact .contact-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 610px) {
  #contact .contact-header {
    padding-left: 25px;
    padding-right: 25px;
  }
}
#contact .contact-header p {
  font-size: 24px;
  color: #362060;
  text-align: center;
}
#contact .contact-set {
  margin: 0px 45px 0px 45px;
  width: calc(100% - 90px);
  height: auto;
  display: inline-block;
}
#contact .contact-set #map {
  height: auto;
}
#contact .contact-set .contact-form {
  background-color: #362060;
  height: auto;
  margin: 0;
  position: relative;
  display: block;
  width: 100%;
}
@media (max-width: 400px), (max-width: 990px) {
  #contact .contact-set .contact-form {
    height: 100%;
  }
}
@media (max-width: 390px) {
  #contact .contact-set .contact-form {
    height: 100%;
  }
}
@media (max-width: 1200px) {
  #contact .contact-set .contact-form {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 500px) {
  #contact .contact-set .contact-form .container {
    padding: 0px;
  }
}
#contact .contact-set .contact-form .contact-data {
  float: left;
  margin: 80px 0px 130px 0px;
  color: #fff;
}
@media (max-width: 1500px), (max-width: 990px) {
  #contact .contact-set .contact-form .contact-data {
    margin: 0px 0 0 0;
  }
}
@media (max-width: 990px) {
  #contact .contact-set .contact-form .contact-data {
    float: none;
    text-align: center;
  }
}
@media (max-width: 550px) {
  #contact .contact-set .contact-form .contact-data {
    text-align: center;
  }
}
#contact .contact-set .contact-form .contact-data img {
  z-index: 1;
  height: 35px;
  margin-bottom: 35px;
}
@media (max-width: 1500px), (max-width: 990px) {
  #contact .contact-set .contact-form .contact-data img {
    max-width: 70%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
#contact .contact-set .contact-form .contact-data p {
  color: #fff;
  font-size: 14px;
  margin: 0px 0px 0px 0px;
  text-align: left;
}
@media (max-width: 990px) {
  #contact .contact-set .contact-form .contact-data p {
    margin: 0px 0px 0px 0px;
  }
}
#contact .contact-set .contact-form .contact-data .fax {
  color: #fff;
  margin-top: 35px;
}
@media (max-width: 1500px) {
  #contact .contact-set .contact-form .contact-data .fax {
    width: 100%;
    margin-top: 25px;
    text-align: left;
  }
}
@media (max-width: 990px) {
  #contact .contact-set .contact-form .contact-data .fax p {
    text-align: center;
  }
}
#contact .contact-set .contact-form .contact-data .adress {
  color: #fff;
}
@media (max-width: 1025px) {
  #contact .contact-set .contact-form .contact-data .adress {
    width: 100%;
    margin-top: 20px;
    text-align: left;
  }
}
@media (max-width: 990px) {
  #contact .contact-set .contact-form .contact-data .adress p {
    text-align: center;
  }
}
#contact .contact-set .contact-form .form {
  float: left;
  display: inline-block;
  margin: 85px 0px 0px 70px;
}
@media (max-width: 1500px) {
  #contact .contact-set .contact-form .form {
    margin: 15px 0px 0px 0px;
  }
}
@media (max-width: 990px), (max-width: 500px), (max-width: 310px) {
  #contact .contact-set .contact-form .form {
    text-align: center;
    float: none;
    display: block;
    width: 100%;
    padding: 5px;
  }
}
#contact .contact-set .contact-form .form h1 {
  font-size: 24px;
  color: #95e1de;
  margin-bottom: 45px;
}
@media (max-width: 400px), (max-width: 990px) {
  #contact .contact-set .contact-form .form h1 {
    margin-bottom: 20px;
  }
}
#contact .contact-set .contact-form .form form {
  color: #fff;
}
@media (max-width: 400px), (max-width: 990px), (max-width: 310px) {
  #contact .contact-set .contact-form .form form {
    text-align: center;
    display: block;
    width: 100%;
  }
}
#contact .contact-set .contact-form .form form input {
  display: block;
  margin-bottom: 20px;
  background: transparent;
  border: 1px solid #95e1de;
  border-radius: 5px;
  width: 100%;
  margin-top: 3px;
}
@media (max-width: 400px), (max-width: 990px) {
  #contact .contact-set .contact-form .form form input {
    margin-bottom: 10px;
  }
}
#contact .contact-set .contact-form .form form label {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
#contact .contact-set .contact-form .text-area {
  margin: 155px 0px 0px 50px;
  display: block;
  color: #fff;
  float: left;
  width: 35%;
  display: inline-block;
}
@media (max-width: 1400px) {
  #contact .contact-set .contact-form .text-area {
    margin: 85px 0 0 0;
    width: 25%;
  }
}
@media (max-width: 1500px) {
  #contact .contact-set .contact-form .text-area {
    margin: 85px 0 0 70px;
  }
}
@media (max-width: 500px), (max-width: 990px) {
  #contact .contact-set .contact-form .text-area {
    text-align: center;
    margin: 0px 0 0 0;
    width: 100%;
    padding: 5px;
  }
}
#contact .contact-set .contact-form .text-area label {
  display: block;
  font-size: 16px;
  font-weight: 400;
}
#contact .contact-set .contact-form .text-area textarea {
  height: 175px;
  display: inline-block;
  background: transparent;
  border: 1px solid #95e1de;
  border-radius: 5px;
  width: 100%;
}
@media (max-width: 400px) {
  #contact .contact-set .contact-form .text-area textarea {
    margin: 0;
    width: 100%;
    height: 100%;
  }
}
#contact #footer {
  margin-top: 85px;
  text-align: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
#contact #footer img {
  margin: 165px 0px 200px 0px;
}
@media (max-width: 990px) {
  #contact #footer {
    width: 100%;
    margin-top: 100px;
  }
}
#contact #footer p {
  margin-top: 50px;
  font-size: 24px;
  width: 100%;
}
#contact #footer img {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
@media (max-width: 1000px) {
  #contact #footer img {
    max-width: 100%;
  }
}
#contact #footer .exit {
  width: calc(100% -90px);
  margin: 0px 45px 50px 45px;
  height: 145px;
  background-color: #f2f2f2;
}
@media (max-width: 800px) {
  #contact #footer .exit {
    height: 80px;
  }
}
#product {
  font-family: 'Lato';
}
#product .product-set {
  margin: 85px auto 0;
  width: calc(100vw - 100px);
  background-image: url("../img/product-background.png");
  background-repeat: repeat-x;
  background-position: 0 150px;
  position: relative;
}
@media (max-width: 1365px) {
  #product .product-set {
    width: 100%;
  }
}
#product .product-set .bottom-text {
  margin: 150px 0px 85px 0;
}
#product .product-set .bottom-text h2::after {
  display: block;
  content: "";
  width: calc(10vw - 50px);
  height: 1px;
  background-color: #95e1de;
  position: absolute;
  bottom: 240px;
  left: 100;
}
#product .product-set .flex-wrapper {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 400px), (max-width: 990px) {
  #product .product-set .flex-wrapper {
    flex-direction: column-reverse;
  }
}
#product .product-set .flex-wrapper .prod-text {
  position: relative;
  color: #362060;
}
#product .product-set .flex-wrapper .prod-text .section-content {
  padding-right: 90px;
}
@media (max-width: 500px) {
  #product .product-set .flex-wrapper .prod-text .section-content {
    padding-right: 0px;
  }
}
#product .product-set .flex-wrapper .prod-text .section-content h2 {
  font-size: 24px;
}
#product .product-set .flex-wrapper .prod-text .section-content p {
  font-size: 16px;
  margin-bottom: 0;
}
@media (max-width: 310px) {
  #product .product-set .flex-wrapper .prod-text .section-content p {
    text-align: center;
    padding: 0px;
  }
}
#product .product-set .flex-wrapper .prod-text .section-content button {
  margin: 40px 0px 40px 0px;
  background: transparent;
  color: #362060;
  border-radius: 5px;
  border: 1px solid #362060;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 800;
}
#product .product-set .flex-wrapper .right-img-wrapper {
  padding-left: 0;
}
#product .product-set .flex-wrapper .right-img-wrapper .right-img {
  width: calc(50vw - 50px);
  height: 100%;
  background-image: url("../img/product-pic.jpg");
  background-position: center;
  background-size: cover;
}
@media (max-width: 400px), (max-width: 990px) {
  #product .product-set .flex-wrapper .right-img-wrapper .right-img {
    width: 100%;
    height: 400px;
    margin-left: 15px;
  }
}
@media (max-width: 1365px) {
  #product {
    width: 100%;
  }
}
#product .video {
  background-color: #eeedf1;
  width: calc(100vw - 44px);
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 50px;
}
#product .video img {
  width: 100%;
}
@media (max-width: 1025px) {
  #product .video img {
    max-width: 100%;
  }
}
