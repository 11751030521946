#contact
	font-family: 'Lato'
	margin-top: 170px

	h1,h2,h3,h4
		margin 0
	.contact-header
		margin:80px 0px 115px 0px
		padding-left: 170px
		padding-right: 170px
		@media( max-width: 610px )
			padding-left: 50px
			padding-right: 50px
		@media( max-width: 610px )
			padding-left: 25px
			padding-right: 25px
		p
			font-size: 24px
			color: dark-font-color
			text-align: center
	.contact-set    
		margin:0px 45px 0px 45px
		width: calc( 100% - 90px )
		height: auto
		display inline-block
		#map
			height: auto


		.contact-form
			background-color: dark-font-color
			height: auto
			margin: 0
			position: relative
			display: block
			width: 100%
			@media( max-width: 400px ),( max-width: 990px )
				height: 100%
			@media( max-width: 390px )
				height: 100%
				
			@media( max-width: 1200px )
				display: flex
				flex-direction: row
			.container
				@media( max-width: 500px )
					padding: 0px
			.contact-data
				float: left
				margin: 80px 0px 130px 0px
				color: #ffffff


				@media( max-width: 1500px ),( max-width: 990px )
					margin: 0px 0 0 0 
				@media( max-width: 990px )
					float: none
					text-align: center
				@media( max-width: 550px )
					text-align: center

				img 
					z-index: 1
					height: 35px
					margin-bottom: 35px
					@media( max-width: 1500px ),( max-width: 990px )
						max-width: 70%
						margin-top: 15px
						margin-bottom: 15px

				p
					color: #ffffff
					font-size: 14px
					margin: 0px 0px 0px 0px
					text-align: left
					@media( max-width: 990px )
						margin: 0px 0px 0px 0px

				.fax
					color: #ffffff    
					margin-top: 35px
					@media( max-width: 1500px )
						width: 100% 
						margin-top: 25px
						text-align: left
					@media( max-width: 990px )
						p
							text-align: center
				.adress
					color: #ffffff
					@media( max-width: 1025px )
						width: 100% 
						margin-top: 20px;
						text-align: left
					@media( max-width: 990px )
						p
							text-align: center
			.form
				float left 
				display: inline-block
				margin: 85px 0px 0px 70px
				@media( max-width: 1500px )
					margin: 15px 0px 0px 0px
				@media( max-width: 990px ),( max-width: 500px ),( max-width: 310px )
					text-align: center
					float: none
					display: block
					width: 100%
					padding: 5px


				h1
					font-size:24px
					color: blue-font-color
					margin-bottom: 45px
					@media( max-width: 400px ),( max-width: 990px )
						margin-bottom: 20px
				form
					color: #FFF
					@media( max-width: 400px ),( max-width: 990px ),( max-width: 310px )
						text-align: center
						display:block
						width: 100%
					input
						display: block
						margin-bottom: 20px
						background: transparent
						border: 1px solid blue-font-color
						border-radius: 5px
						width: 100%
						margin-top: 3px
						@media( max-width: 400px ),( max-width: 990px )
							margin-bottom: 10px

					   
					label  
						font-size: 16px
						font-weight: 400
						color: #FFF
			.text-area
				margin: 155px 0px 0px 50px
				display: block
				color: #FFF
				float: left
				width: 35%
				display: inline-block
				@media( max-width: 1400px )
					margin: 85px 0 0 0
					width: 25%
				@media( max-width: 1500px )
					margin: 85px 0 0 70px
				@media( max-width: 500px ),( max-width: 990px )
					text-align: center
					margin: 0px 0 0 0
					width: 100%
					padding: 5px

				label 
					display: block
					font-size: 16px
					font-weight: 400
				textarea
					height: 175px
					display: inline-block
					background: transparent
					border: 1px solid blue-font-color
					border-radius: 5px
					width: 100%
					@media( max-width: 400px )
						margin: 0
						width: 100%
						height: 100%
	#footer
		margin-top: 85px
		text-align: center
		display: flex
		flex-direction: column
		z-index: 1
		img 
			margin: 165px 0px 200px 0px
		@media(max-width: 990px)
			width: 100%
			margin-top: 100px
		p
			margin-top: 50px
			font-size: 24px
			width: 100%
		img 
			margin-top: 100px
			margin-left: auto
			margin-right: auto
			width: 80%
			@media( max-width: 1000px )
				max-width: 100%
		.exit
			width: calc(100%-90px)
			margin: 0px 45px 50px 45px
			height: 145px
			background-color: #f2f2f2
			@media( max-width: 800px )
				height: 80px
				