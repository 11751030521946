#effectivity
	margin-top: 155px
	@media( max-width:500px)
		margin-top: 100px

	h1,h2,h3,h4
		margin 0
			
	.text-centered
		font-size:	24px
		color: dark-font-color
		font-weight: 300
		padding: 55px 30px
		text-align: center

	.infos-wrapper
		width: calc( 100vw - 62px )
		margin: 0 auto
		color: #fff
		text-align: center
		overflow: hidden
		padding-top: 205px
		@media( max-width:500px)
			padding-top: 55px

		.container
			position: relative
			background-color: dark-font-color

			.first-row
				.item
					position: relative
					
					img
						position: absolute
						bottom: calc( 100% - 35px )
						left: 50%
						transform: translateX( -50% )
					.visi
						@media( max-width:990px)
							display: block
							bottom: calc( 100% - 35px )
						@media (max-width: 768px)
							bottom: calc( 100% + 25px )
						@media( max-width:500px)
							display: none
							
					.invi
						@media( max-width: 400px ),( max-width:990px)
							display: none

					h3
						font-size: 49px
						font-weight: 900
						color: #fff
						margin: 60px 0 20px


					p
						color: #fff
						text-align: center
						font-weight: 300

			.second-row
				p
					font-weight: 300
					color: #fff
					text-align: center
					font-size: 24px
					line-height: 30px
					margin-bottom: 85px
					@media( max-width:800px)
						margin-bottom: 60px
					@media( max-width:500px)
						font-size: 20px


					&::before
						content: ""
						display: block
						margin: 0 auto
						width: 102px
						height: 1px
						background-color: blue-font-color
						margin-bottom: 55px

			.third-row
				.item
					padding-bottom: 125px
					@media( max-width: 800px )
						padding-bottom: 60px
						line-height: 30px
					@media( max-width:500px)
						padding: 10px
					
					h3
						font-size: 100px
						color: blue-font-color
						font-weight: 900
						@media( max-width: 1025px )
							font-size: 80px
						@media( max-width: 800px )
							font-size: 49px
							padding-bottom: 0px
							


					p
						color: #fff
						text-align: center
						padding: 0 30px
						@media( max-width: 800px )
							margin-top: 20px
						@media( max-width: 310px )
							padding: 0 00px

				.item
					&:first-child,
					&:last-child
						@media( max-width: 500px )
							padding: 10px
			&::before
				content: ""
				display: block
				position: absolute
				right: 100%
				width: 100vw
				top: 0
				height: 100%
				background-color: dark-font-color

			.right-picto-wrapper
				background-color: blue-font-color
				position: absolute
				left: 100%
				height: 100%
				width: 100vw
				top: 0
				background-image: url('../img/effectivity/right-picto.png')
				background-repeat: no-repeat

	.text
		margin: 100px 0 0
		h2	
			margin-bottom: 80px
		p
			margin-bottom: 45px

		span
			font-weight: bold