#specification

    font-family: 'Lato'
    h1,h2,h3,h4
        margin 0
    .list
        .text-h2
            margin-bottom: 55px

    .spec
        display: flex
        flex-direction: row
        justify-content: space-between
        margin-top: 80px
        @media( max-width: 400px ),( max-width:990px)
            flex-direction: column
        .spec-block
            height: 100%
            border: 1px solid dark-font-color
            width: calc(33.3333333% - 10px)
            border-radius: 2px
            text-align: center
            position: relative
            overflow: hidden
            @media( max-width:990px)
                width: 100%
                margin-bottom: 25px
                margin-left: auto
                margin-right: auto
            @media( max-width: 400px )
                max-width: 100%
                margin-bottom: 15px

            .first-side-wrapper
            
                .spec-block-pic
                    display: inline

                    img
                        margin: 90px 0px 80px 0px
                        height: 120px
                        @media(max-width:500px)
                            margin: 50px 0px 40px 0px
                            width: 35%
                            height: 100px

                .spec-block-text
                    margin-bottom: 115px
                    color: dark-font-color
                    text-transform: uppercase
                    p
                        margin: 0
                        font-weight: 900
                        font-size: 28px    
                        text-align: center
                        @media(max-width:990px)
                            font-size: 26px
                        @media(max-width:500px)
                            font-size: 22px


            .second-side-wrapper			
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                border-radius: 2px
                text-align: center
                background-color: dark-font-color
                opacity: 0
                margin-top: 100%

                h1
                    color: blue-font-color
                    text-transform: uppercase
                    font-size: 30px
                    margin: 50px 0px 45px 0px
                    font-weight: 900
                    text-align: center
                    @media(max-width:990px)
                        margin: 25px 0px 15px 0px 
                    @media(max-width:500px)
                        font-size: 30px
                        margin: 25px 0px 15px 0px 



                .spec-block-middle-text

                    text-align: left
                    line-height: 27px
                    color: #fff
                    margin: 0px 40px 0px 40px
                    @media(max-width:400px)
                        margin: 0px 20px 0px 20px

                    p
                        font-size: 16px
                        margin: 0
                        color: #FFF 
                        line-height: 25px
                        @media(max-width:1200px)
                            font-size: 13px
                        @media(max-width:990px)
                            font-size: 20px
                            text-align: center
                            margin: 0px 90px 40px 90px
                        @media(max-width:600px)
                            font-size: 18px
                            line-height: 20px
                            margin: 0px 75px 40px 75px
                        @media(max-width:500px)
                            font-size: 14px
                            line-height: 16px
                            margin: 0px 35px 20px 35px
                        @media(max-width:370px)
                            margin: 0px 10px 10px 10px
                            font-size: 14px
                            line-height: 16px
            &:hover
                .second-side-wrapper
                    opacity: 1
                    margin-top: 0
                    transition: all 0.2s ease-out 
    .text-spec
    
        margin: 95px 0px 85px 0px
        h2
            margin: 80px 0px 65px 0px
            color: dark-font-color
            font-size: 24px
            text-align: center
            @media(max-width:400px)
                font-size: 16px

    .pdf-gen
        display: flex
        flex-direction: row
        justify-content: center
        margin-bottom: 150px
        @media( max-width: 400px ),( max-width: 600px )
            flex-direction: column
        .clouds
            @media( max-width: 400px ),( max-width: 600px )
                margin : 0
                margin-right: auto
                margin-left: auto

            img
                text-align: center
                padding-left: 25px
                padding-right: 25px



            p
                margin : 0
                width: 100%


        .clouds:first-child
            margin-right : 190px
            @media( max-width: 400px ),( max-width: 600px )
                margin : 0
                margin-bottom: 35px
                margin-right: auto
                margin-left: auto

