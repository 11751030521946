#benefits

	font-family: 'Lato'
	margin: 170px 0px 0px 0px
	@media( max-width: 500px )
		margin-top: 75px

	h1,h2,h3,h4
		margin 0
	.list
		margin: 50px 0 
	.ca
		display: flex
		margin: 115px 0px 115px 0px
		flex-direction: row
		align-items: center
		justify-content: space-between
		color: dark-font-color
		@media( max-width: 400px ),( max-width:990px)
			flex-direction: row
		@media( max-width: 400px )
			flex-direction: column
		img 
			@media( max-width:990px)
				width: 100%


		p
			margin-top: 25px
			width: 90%
			margin: 0 auto
			font-size: 18px
			text-align: center
			@media( max-width:990px)
				font-size: 16px
				line-height: 24px
			@media( max-width:600px)
				font-size: 14px
			@media( max-width:400px)
				font-size: 18px


		h1	
			font-size: 48px
			font-weight: 900
			text-align: center
			@media( max-width:990px)
				font-size: 40px
			@media( max-width:500px)
				font-size: 32px

		.text-col
			display: block
			width: 33%
			@media( max-width: 500px )
				width: 100%

		.arrow-col
			display: block
			@media( max-width: 400px ),( max-width:990px)
				margin: 15px 0px 15px 0px 
				img 
					display: none

	.pic
		display: inline-block
		height: 100%
		margin-top: 75px
		width: 100%

		.pic-img
			img 
			@media( max-width: 400px )
				display: block
				float: left
			@media( max-width: 350px )
				width: 100%
				text-align: center


	.save
		width: 100%
		display: inline-block
		background-image: url( '../img/zzz.png' )
		position: relative
		height: 630px
		background-size: cover
		background-position: center
		@media( max-width: 400px )
			width: 100%

		&::before
			height: 630px
			width: 100%
			display: inline-block
			content: ""
		.blend
			background-color: rgba( dark-font-color, 0.77 )
			position: absolute
			top: 45px
			left: 50px
			bottom: 45px
			width: calc( 100% - 100px )
			height: calc( 100% - 90px )
			@media(max-width:500px)
				left:25px 
				width: calc( 100% - 50px )
				height: calc( 100% - 40px )
				top: 20px
		.container
			position: relative
			height: calc( 100vh - 90px )

		.save-text
			position: absolute
			top: 50%
			transform: translateY( -50% )
			left: 20%
			right: 20%
			z-index: 1
			color: #fff
			max-width: 100%
			text-align: center



			h1
				font-weight: 500
				font-size: 37px
				text-transform: uppercase
				text-align: center
				color: blue-font-color
				@media( max-width: 970px )
					font-size: 24px
				@media( max-width: 500px )
					font-size: 20px


			h2
				font-size: 149px
				font-weight: 900
				color: #fff
				margin: 40px 0 55px
				@media( max-width: 400px ),( max-width: 970px )
					font-size: 66px
					margin: 10px 0 15px
				@media( max-width: 300px )
					font-size: 66px

			p
				font-size: 24px
				font-weight: 300
				text-align: center
				color: #fff
				@media( max-width: 500px )
					font-size: 20px


	