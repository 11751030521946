#changes

	font-family: 'Lato'

	h1,h2,h3,h4
		margin 0
	.loc
		display: flex
		align-items: center;
	.text-h2
		margin: 85px 0px 45px 0px
		color: dark-font-color
		font-weight: 800
		font-size: 24px
	.pic-text
		@media(max-width:360px)
			h1
				font-size: 16px
	.ul-cropp
		list-style-image: url("dist\img\cropp.png");
		list-style-type: none
		color: dark-font-color
		font-size: 16px
		margin:0px 0px 95px 40px

	.colo-photo
		margin-top: 75px
		@media(max-width: 500px)
			margin-top: 25px
		img
			max-width: 100%
		.pic2
			max-width: 47%
			float: left
			display:block
			margin-right: 45px
