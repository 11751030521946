#analys

	font-family: 'Lato'

	h1,h2,h3,h4
		margin 0

	.photo
		margin-top: 65px
		margin-bottom: 130px
		@media(max-width:500px)
			margin-bottom: 65px
		img
			@media( max-width: 1500px )
				max-width: 100%

	.second
		margin-top: 60px
 
	.scope-logo
		margin: 210px 0px 100px 0px
		display: inline-block
		@media(max-width: 500px)
			margin: 110px 0px 50px 0px 


		.scope-text
			display: block
			height: 100%
			float: left
			width: 52%
			margin-left: 30px
			@media( max-width: 300px ),( max-width: 990px ) 
				margin-left: 0px
				float: left
				margin-left: auto
				margin-right: auto
				text-align: center
			
			
			h1
				text-transform: uppercase
				font-size: 65px
				color: dark-font-color
				font-weight: 900

				@media( max-width: 990px )
					font-size: 44px
					line-height: 56px
					float: left
					margin-left: 20px
					text-align: left
				@media( max-width: 580px )
					font-size: 34px

				@media( max-width: 500px )
					font-size: 26px
					line-height: 34px
				@media( max-width: 415px )
					font-size: 16px
					line-height: 26px
					margin-left: 10px
